
import React, { useEffect, useState, useCallback } from 'react';
import "dropzone/dist/dropzone.css";
import clsx from 'clsx';

export const Settings = () => {
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    const startHandler = useCallback(async () => {
        fetch('upload/settings.php', {
            method: "PUT",
            body: JSON.stringify({ height: height, width: width }),
        }).then((response) => {
            if (response.status === 200) {
                return response.text();
            }
            return 'Ein Fehler ist aufgetreten.'
        }).then(data => {
            document.querySelector("#result-settings").innerHTML = data;
        });
    }, [height, width]);

    useEffect(() => {
        fetch('assets/settings.json').then(response => {
            response.json().then(json => {
                setHeight(json.height ? json.height : '');
                setWidth(json.width ? json.width : '');
            });
        })
    }, []);

    return (
        <div className="margin-top--lg">
            <h2>Einstellungen</h2>
            <div id="result-settings"></div>
            <div className='flex flex-col'>
                <div>
                    <label className="inline-block w-40">Höhe</label>
                    <input className="inline-block w-40 m-1 p-1 border border-black rounded-md" value={height} onChange={(event) => setHeight(event.target.value)}></input>
                </div>
                <div>
                    <label className="inline-block w-40">Breite</label>
                    <input className="inline-block w-40 m-1 p-1 border border-black rounded-md" value={width} onChange={(event) => setWidth(event.target.value)}></input>
                </div>
            </div>
            <button className={clsx("button", "button--primary", "margin-vert--md")} onClick={startHandler}>Speichern</button>
        </div>)
}