import React from 'react';
import { Content } from '../components/content';
import { Compile } from '../components/compile';
import { Settings } from '../components/settings';

export const Upload = () => {
    return (
        <main>
            <div className="margin--lg">
                <Content />
                <Compile />
                <Settings />
            </div>
        </main>
    )
}