import './App.css';
import { Szene } from './pages/szene';
import { Upload } from './pages/upload';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Upload />} />
        <Route path="/szene" element={<Szene />} />
      </Routes>
    </BrowserRouter >
  );
}

export default App;

