import { useEffect, useRef, useState, useCallback } from 'react';
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import clsx from 'clsx';

export const Content = () => {
    const [dropzone, setDropzone] = useState(null);
    const refDropzone = useRef(null);

    useEffect(() => {
        console.log("use effect", refDropzone.current);
        const myDropzone = new Dropzone(refDropzone.current, {
            url: "#",
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
            dictDefaultMessage: "Datei auswählen",
            dictRemoveFile: "Datei entfernen",
            acceptedFiles: "image/png",
            maxFilesize: 10240,
        });
        setDropzone(myDropzone);
    }, []);

    const startHandler = useCallback(async () => {
        const file = dropzone.files[0];
        const formData = new FormData();
        formData.append('file', file)

        fetch('upload/content.php', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if (response.status === 200) {
                return response.text();
            }
            return 'Ein Fehler ist aufgetreten.'
        }).then(data => {
            document.querySelector("#result-content").innerHTML = data;
        });
    }, [dropzone]);

    return (
        <div className="margin-bottom--lg">
            <h2>AR-Szene</h2>
            <div id="result-content"></div>
            <div ref={refDropzone} className="dropzone"></div>
            <button className={clsx("button", "button--primary", "margin-vert--md")} onClick={startHandler}>Hochladen</button>
        </div>)
}